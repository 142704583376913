export const tables = {
  ".builder-custom-code":{
  table: {
    tableLayout: "fixed",
    width: "100%",
    borderCollapse: "collapse",
  }},

  thead: {
    bg: "tableHeadBG",
    color: "white",
  },

  tfoot: {
    bg: "tableFootBG",
    color: "white",
  },
  "thead th:nth-of-type(1)": {
    width: "30%",
  },

  "thead th:nth-of-type(2)": {
    width: "20%",
  },

  "thead th:nth-of-type(3)": {
    width: "15%",
  },

  "thead th:nth-of-type(4)": {
    width: "35%",
  },
 
  th: {

    padding: 3,
  },
  td: {
    padding: 3,
  },

  /* zebra striping */

  "tbody tr:nth-of-type(odd)": {
    bg: "tableAltBGDark",
  },

  "tbody tr:nth-of-type(even) ": {
    bg: "tableAltBG",
  },
};
