import { gradients } from "./colors";

const backgrounds = {
  backgrounds: {
    primary: {
      bg: "primary",
      gradient: {
        ...gradients.primary,
        shadow: {
          ...gradients.primary,
          boxShadow:
            "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
        },
      },

      shadow: {
        ...gradients.primary,
        boxShadow:
          "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      },
    },
    shadow: {
      boxShadow:
        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
    },
  },
};

export default backgrounds;
