
/*
here we will define any special wordpress added styles 
*/


export const wordpress = {
  '[data-align="right"]': {
    textAlign: "right",
  },
  ".aligncenter": {
    display: "block",
    ml:"auto",
    mr:"auto"
  },
};