export const Hero = {
  background: {
    position: "absolute",
    top: 0,
    height: "560px",
    width: "100%",
    zIndex: -1,
  },
  contentWrapper: {
    //mt:1,
    top: [0, 4],
    left: [0, 0],
    //left: 0,
    right:0,
    variant: "layout.box",
    position: "absolute",
    width: ["100%"],
    justifyContent: "flex-start",
    alignItems: ["flex-start", null, null],
    display: "flex",
    color: "white",
    flexDirection: ["column", "row"],
    pl:[2,0],
  },
  h1: {
    display: "flex",
    flexDirection: ["row", "column"],
    fontWeight: ["bold", "body"],
    fontSize: [3, 6],
    textTransform: "uppercase",
    alignItems: ["center", "flex-start"],
    span: { lineHeight: "56px" },
  },
  message: {
    width: ["100%"],
    fontWeight: ["body"],
    fontSize: [1, 5],
    textAlign: ["left"], 
    color: "primary",
  },
};
