export const alerts ={
  alerts: {
    primary: {
      color: 'text',
      bg: 'alert',
      fontWeight:"body"
    },
    muted: {
      color: 'text',
      bg: 'muted',
    },
  },
}