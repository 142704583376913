const modals = {
  modals: {
    primary: {
      backDrop: {
        zIndex: 9999,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        bg: "modalBackdrop",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
        //pointerEvents: "none",
      },
      container: {
        position: "relative",
        bg: "primaryLightBG",
        p: 4,
        borderRadius: 4,
      },
      close: {
        position: "absolute",
        top: 0,
        right: 0,
        color: "white",
      },
    },
    video: {
      backDrop: {
        variant: "modals.primary.backDrop",
      },
      container: {
        position: "relative",
        height: ["50%", "90vh"],
        width:["100vw","80%"],
        p: 4,
      },
      close: {
        variant: "modals.primary.close",
        color: "white",
      },
    },
    dark: {
      backDrop: {
        variant: "modals.primary.backDrop",
      },
      container: {
        variant: "modals.primary.container",
        bg: "primary",
      },
      close: {
        variant: "modals.primary.close",
        color: "white",
      },
    },
  },
};

export default modals;
