import ColorPallet from "./color-pallet.interface";

const pallet: ColorPallet = {
  transparent: "transparent",
  black: "#000",
  white: "#ffffff",
  whiteTransparent: [
    "#FFFFFF10",
    "#FFFFFF20",
    "#FFFFFF30",
    "#FFFFFF40",
    "#FFFFFF50",
    "#FFFFFF60",
    "#FFFFFF70",
    "#FFFFFF80",
    "#FFFFFF90",
    "#FFFFFF99",
  ],
  //Blue
  primary: {
    DEFAULT: "#05539C",
    "50": "#8BC5FB",
    "100": "#73B9FA",
    "200": "#41A0F9",
    "300": "#1087F7",
    "400": "#076DCD",
    "500": "#05539C",
    "600": "#03396B",
    "700": "#00385F",
    "800": "#000408",
    "900": "#000000",
  },

  //Orange
  secondary: ["#E57915"],
  secondaryTransparent: [],

  //blue-gray
  neutrals: [
    "#F0F4F8",
    "#D9E2EC",
    "#BCCCDC",
    "#9FB3C8",
    "#829AB1",
    "#627D98",
    "#486581",
    "#334E68",
    "#243B53",
    "#102A43",
  ],

  //Dark
  supportingA: [
    "#000000",
    "#060606",
    "#0C0D0D",
    "#131314",
    "#191A1B",
    "#202122",
    "#262728",
    "#2C2E2F",
    "#333436",
    "#393B3D",
    "#404244",
    "#404244",
    "#535456",
    "#666769",
    "#797A7C",
    "#8C8D8E",
    "#9FA0A1",
    "#B2B3B4",
    "#C5C6C6",
    "#D8D9D9",
    "#EBECEC",
    "#FFFFFF",
  ],

  supportingB: [],
};

export default pallet;
