//import { shadows } from "./colors";

import { WpCategoryFieldsEnum } from "../../gatsby-graphql";

const cardCommon = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  bg: "white",
  p: 4,
  //boxShadow: shadows.grey,
  borderRadius: "4px",
};

const cards = {
  cards: {
    primary: {
      bg: "white",
      //padding: 4,
      borderRadius: "4px",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      titleBar: {
        bg: "primary",
        minHeight: "54px",
        color: "white",
      },
    },
    subMenu: {
      bg: ["transparent", "white"],
      //padding: 4,
      borderRadius: [0, "4px"],
      boxShadow: ["none", "0 0 8px rgba(0, 0, 0, 0.125)"],
      p: [0, 4],
      display: ["block", "none"],
      position: ["relative", "absolute"],
      width: ["100%", "300px"],
      top: [0, 40],
    },
    feature: {
      mt: 60,
      px: 3,
      pb: 3,
      bg: "white",
      //padding: 4,
      borderRadius: "4px",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      textAlign: "center",
      titleBar: {
        display: "flex",

        alignItems: "center",

        color: "text",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    radioIcon: {
      variant: "cards.primary",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        variant: "cards.radioIcon.selected",
      },
      selected: {
        variant: "cards.primary",
        bg: "primary",
        color: "white",
      },
    },
    icon: {
      variant: "cards.primary",
      p: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "100px",
      bg: "primaryLightBG",
      h3: {
        width: "70%",
        fontWeight: "light",
        textAlign: "left",
      },
      svg: {
        background: `linear-gradient(90deg, #004257 0%, #004D4B 79.17%);`,

        fontSize: "80px",
        //borderRadius: "50%",
        color: "primaryLight",
        minHeight: "100px",
      },
    },
  },
};

export default cards;
