import { tables } from "./tables";
import typography from "./typeography";

export const accordion = {
  accordion: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    item: {
      width: "100%",
      mt: 2,
      variant: "cards.primary",
      bg: "white",
      color: "text",

      p: { mb: 1, lineHeight: 3 },

      a: {
        color: "primary",
      },
    },
    itemPanel: {
      ...tables,
      ...typography,
      width: "100%",
      display: "block",
      p: 3,
      hidden: {
        display: "none",
      },
    },
    header: {
      width: "100%",
      color: "white",
      bg: "primary",
      textAlign: "left",
      fontSize: [2, 3, 5],
    },
    chevron: {
      transform: `rotate(90deg)`,
      color: "white",
      open: {
        transform: `rotate(270deg)`,
        bg: {
          //bg: "white",
          borderRadius: "50%",
          minWidth: "30px",
          minHeight: "30px",

          color: "white",
        },
      },
    },
  },
};
